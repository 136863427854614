import type { Payload } from "@local/payload-client/src/types";
import { ContentBlockServer } from "../ContentBlock";
import Image from "@/components/Image";
import ButtonServer, { ButtonIntent, ButtonStretch } from "../ui/button";
import { BunnyLoader } from "@local/utils/src/bunnyLoader";
import { ArrowLeftIcon } from "../ui/icons";
import { barlowCondensed } from "@/utils/fonts";
import { TrustpilotMicroCombo } from "../TruspilotWidgets";
import styles from "./styles.module.css";
interface Props {
  block: Payload.NewArrivalsBlock;
}
export const NewArrivalsWithTitleBlock = ({
  block
}: Props) => {
  const headline = block.headline ?? 'Explore our <span className="font-semibold">new arrivals</span>';
  return <ContentBlockServer className="flex flex-col justify-center py-2 mb-4 !max-w-6xl" data-sentry-element="ContentBlockServer" data-sentry-component="NewArrivalsWithTitleBlock" data-sentry-source-file="index.tsx">
      <div className="mx-6 xl:mx-0">
        <h2 className={`${barlowCondensed.className} flex md:relative text-5xl uppercase tracking-[-0.02em] leading-tight`}>
          <ArrowLeftIcon className="hidden xl:block mr-2 md:mr-0 md:absolute w-9 rotate-180 -left-12 top-3 text-[#E5E5E5]" data-sentry-element="ArrowLeftIcon" data-sentry-source-file="index.tsx" />
          <p dangerouslySetInnerHTML={{
          __html: headline
        }} />
        </h2>

        <div className="mt-6 mb-8">
          {block.product?.slice(0, 2).map((product, i) => {
          return <div key={product.id} className={`md:h-[390PX] w-full mb-10 bg-[#17181A] flex flex-col ${i % 2 ? "md:flex-row-reverse" : "md:flex-row"} ${styles.NewArrivalsWithTitleBlock} overflow-hidden`}>
                <div className="md:w-1/2 h-1/2 aspect-video w-full md:h-full md:aspect-auto overflow-hidden">
                  <Image src={product.image?.url as string} alt={product.product?.name as string} width={640} height={390} fill sizes="960px" className="object-cover object-top" loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} />
                </div>
                <div className="flex flex-col items-start justify-center text-white md:w-1/2 py-4 px-6 md:p-16">
                  <p className={`${barlowCondensed.className} md:pt-6 text-3xl md:text-5xl uppercase`} dangerouslySetInnerHTML={{
                __html: product.title
              }} />
                  <p className="py-2 text-[#C9C9C9] text-base">
                    {product.description}
                  </p>
                  <ButtonServer content="Shop Now" url={`/products/${product.product?.slug}`} className="!w-[274px] my-1.5 sm:my-4 text-lg uppercase md:bg-[#17181A] border-white md:text-white hover:bg-white hover:text-black !font-semibold" intent={ButtonIntent.OutlinedCTA} stretch={ButtonStretch.flexible} />
                  <TrustpilotMicroCombo dark tall cssClass="!mt-6 md:!mt-2 -ml-12" />
                </div>
              </div>;
        })}
        </div>
      </div>
    </ContentBlockServer>;
};
export default NewArrivalsWithTitleBlock;